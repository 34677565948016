import React, { useState } from "react";
import classnames from "classnames";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Config from "./Config";
import Paid from "./Paid";
import Games from "./Games";
import YearSelect from "./YearSelect";

export default function Admin() {
  const [year, setYear] = React.useState("2023");
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div>
      <YearSelect value={year} prefix="" cb={setYear} />
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            Config
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            Users
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => {
              toggle("3");
            }}
          >
            Games
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Config year={year} />
        </TabPane>
        <TabPane tabId="2">
          <Paid year={year} />
        </TabPane>
        <TabPane tabId="3">
          <Games year={year} />
        </TabPane>
      </TabContent>
    </div>
  );
}
