import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Navigation from "./components/Navigation";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import * as ROUTES from "./constants";
import Landing from "./components/Leaderboard";
import SignUp from "./components/SignUp";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import Account from "./components/Account";
import Admin from "./components/Admin";
import Home from "./components/Home";
import Picks from "./components/Picks";
import User from "./components/User";
import app from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";
import PickWrapper from "./components/PickWrapper";
import SignIn from "./components/SignIn";

function App() {
  const [user, initialising, error] = useAuthState(app.auth());

  return (
    <Router>
      <Navigation />
      <div style={{ margin: "2rem", textAlign: "center" }}>
        <Route path={ROUTES.LANDING} component={Landing} />
        <Route path={ROUTES.SIGN_IN} component={SignIn} />
        {/* <Route path={ROUTES.PASSWORD_FORGET} component={ForgotPassword} /> */}
        <Route exact path={ROUTES.HOME}> <Home /> </Route>
        <PrivateRoute path={"/user/:id"} component={User} />
        <PrivateRoute path={ROUTES.ACCOUNT}> <Account /> </PrivateRoute>
        <PrivateRoute path={ROUTES.ADMIN}> <Admin /> </PrivateRoute>
        <PrivateRoute path={ROUTES.PICKS}><PickWrapper /></PrivateRoute>
      </div>
    </Router>
  );
}

function PrivateRoute({ children, ...rest }) {
  const [user, initialising, error] = useAuthState(app.auth());
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user != null ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

export default App;
