import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";
import app from "firebase/app";
import * as ROUTES from "../constants";
import { useAuthState } from "react-firebase-hooks/auth";
import { useObjectVal } from "react-firebase-hooks/database";
import Logo from '../horz-logo.svg'

const Navigation = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, initialising, error] = useAuthState(app.auth());
  const [config, loading, configError] = useObjectVal(app.database().ref('config'));
  console.log(config, configError);
  const toggle = () => setIsOpen(!isOpen);
  let isAdmin = false;
  if(!loading && user) {
    isAdmin = config.admins.includes(user.email)
    console.log('admin?', isAdmin, user.email)
  }

  return (
    <div>
      <Navbar color="dark" dark expand="md">
        {/* <NavbarBrand href="/">Griesers Bowl Picks</NavbarBrand> */}
        <NavbarBrand href="/"><img style={{height: '50px', width: 'auto'}} src={Logo} alt="logo" /></NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavLink tag={Link} to={ROUTES.HOME}>
              Home
            </NavLink>
            <NavLink tag={Link} to={ROUTES.LANDING}>
              Leaderboard
            </NavLink>
            {/*<NavLink tag={Link} to={ROUTES.ACCOUNT}>
              Account
  </NavLink>*/}
            {isAdmin ? <NavLink tag={Link} to={ROUTES.ADMIN}>
              Admin
            </NavLink> : null}
            {
              user != null ? <NavLink tag={Link} to={ROUTES.PICKS}>
              Picks
            </NavLink> : null }
          </Nav>
          <Nav>
            {user != null ? (
              <Button onClick={() => app.auth().signOut()}>Sign Out</Button>
            ) : (
              <>
                <NavLink tag={Link} to={ROUTES.SIGN_IN}>
                  Sign In
                </NavLink>
              </>
            )}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Navigation;
