import React from "react";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import app from "firebase/app"
// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'redirect',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/home',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    app.auth.GoogleAuthProvider.PROVIDER_ID,
    app.auth.FacebookAuthProvider.PROVIDER_ID,
    app.auth.TwitterAuthProvider.PROVIDER_ID,
    app.auth.EmailAuthProvider.PROVIDER_ID
  ]
}
export default function SignIn() {
  const [user, loading, error] = useAuthState(app.auth());
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };
  console.log(from)
  if(user) {
    history.replace(from);
  }
  return (
    <div>
      <StyledFirebaseAuth uiConfig={{
        ...uiConfig,
        signInSuccessUrl: from.pathname
      }} firebaseAuth={app.auth()} />
    </div>
  );
}
