import { Spinner } from 'reactstrap';


import React from 'react'

export default function Loading() {
  return (
    <div style={{ width: '100%', height: '100%' }} color="dark">
      <Spinner style={{height: '5rem', width: '5rem', margin: 'auto' }} />
    </div>
  )
}
