import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import app from "firebase/app";
import Loading from "./Loading";
import { Label } from 'reactstrap'
import Toggle from 'react-toggle'
export default function PickLists({ ids, yearKey }) {
  console.log(ids, yearKey)
  const [picks, initPicks, picksError] = useCollection(
    app
      .firestore()
      .collection(`picks${yearKey}`)
      .where(app.firestore.FieldPath.documentId(), "in", ids)
  );
  if (initPicks) {
    return <Loading />;
  }
  return <div>
    {picks.docs.map(doc => {
      let p = doc.data();
      return (<div key={doc.id}>
        <Label>{p.name}</Label><br />
        <Toggle checked={p.paid} onChange={() => app.firestore().collection(`picks${yearKey}`).doc(doc.id).set({
          paid: !p.paid
        }, {
          merge: true
        })} />
      </div>)
    })}
  </div>;
}
