import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import Loading from "./Loading";
import app from "firebase/app";
import Picks from "./Picks";
import YearSelect from "./YearSelect";

export default function User() {
  let { id } = useParams();
  const [year, setYear] = React.useState("2023");
  const [value, loading, error] = useCollectionData(
    app.firestore().collection(`picks${year}`).where("userId", "==", id),
  );
  console.log(value);
  const [userValue, userLoading, userError] = useDocumentData(
    app.firestore().doc(`users/${id}`),
  );
  if (loading || userLoading) {
    return <Loading />;
  }
  return (
    <div>
      <h1>{userValue.displayName}</h1>
      <YearSelect value={year} prefix="" cb={setYear} />
      {value.length > 0 && (
        <Picks
          user={userValue}
          userPicks={value}
          disabled={true}
          userView={true}
          year={year}
        />
      )}
    </div>
  );
}
