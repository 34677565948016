import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  TabContent,
  Alert,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  ButtonGroup,
  CardText,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import app from "firebase/app";
import classnames from "classnames";
import Loading from "./Loading";
import { useAuthState } from "react-firebase-hooks/auth";

export const gamePicks = {
  name: "New Picks",
  picks: [],
};

export default function Picks({
  user,
  userPicks,
  pickIds: initPickIds,
  disabled,
  year,
  userView,
}) {
  const [value, loading, error] = useCollection(
    app
      .firestore()
      .collection(`games${year}`)
      .where("Enabled", "==", true)
      .orderBy("DateTime", "asc")
  );
  console.log("value", value && value.docs.map((v) => v.data()));
  if (error) console.log(error);
  const nationalChampionship =
    value && value.docs.find((v) => v.data().Title === "National Championship");
  const nationalChampionshipIndex =
    value &&
    value.docs.findIndex((v) => v.data().Title === "National Championship");
  const champAwayIndex =
    value &&
    value.docs.findIndex(
      (v) =>
        v.data().GameID.toString() === nationalChampionship.data().AwayTeamID
    );
  const champHomeIndex =
    value &&
    value.docs.findIndex(
      (v) =>
        v.data().GameID.toString() === nationalChampionship.data().HomeTeamID
    );
  const [activeTab, setActiveTab] = useState(0);
  const [picks, setPicks] = useState(userPicks);
  const [pickIds, setPickIds] = useState(initPickIds);
  const [validationMessage, setValidationMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const deleteFrom = (i) => {
    let p = [...picks];
    p.splice(i, 1);
    setPicks(p);
    if (i === activeTab) setActiveTab(0);
  };

  const setKeyVal = (key, val, index) => {
    let p = [...picks];
    p[index][key] = val;
    setPicks(p);
  };

  const pickGame = (index, winner) => {
    let p = [...picks];
    let games = p[activeTab].picks;
    games[index] = winner;
    if (index === champHomeIndex || index === champAwayIndex) {
      games.splice(nationalChampionshipIndex, 1);
    }
    p[activeTab].picks = games;
    setPicks(p);
  };

  const savePicks = async () => {
    let msg = isValid();
    if (msg === "") {
      let ids = [];
      for (var i = 0; i < picks.length; i++) {
        try {
          const { id } = await app
            .firestore()
            .collection(`picks${year}`)
            .add(picks[i]);
          ids.push(id);
        } catch (e) {
          alert("Whoops an error occurred!");
        }
      }
      if (pickIds != null && pickIds.length > 0) {
        for (var j = 0; j < pickIds.length; j++) {
          console.log("Deleting doc", pickIds[j]);
          await app
            .firestore()
            .collection(`picks${year}`)
            .doc(pickIds[j])
            .delete();
        }
      }
      console.log(`SAVING PICKS FOR picks${year}`);
      const returned = await app
        .firestore()
        .collection("users")
        .doc(user.uid)
        .set(
          {
            [`picks${year}`]: ids,
            displayName: user.displayName,
          },
          {
            merge: true,
          }
        );
      setPickIds(ids);
      setValidationMessage("");
      setSuccessMessage("Picks saved successfully!");
    } else {
      setValidationMessage(msg);
    }
  };

  const isValid = () => {
    let message = "";
    let games = value.docs;
    var top = document.getElementById(`top-of-it-all`);
    console.log("scroll-top", top);
    top.scrollIntoView({ behavior: "smooth" });
    for (var i = 0; i < picks.length; i++) {
      if (picks[i].name === "") {
        // TODO prevent names that already exist
        return `Pick list at tab number ${i + 1} has blank name!`;
      }

      if (!picks[i].picks) {
        return `Pick list ${picks[i].name} is missing picks!`;
      }
      let pickList = picks[i].picks;
      for (var j = 0; j < games.length; j++) {
        if (!pickList[j]) {
          if (message === "") {
            var element = document.getElementById(games[j].data().GameID);

            element.scrollIntoView({ behavior: "smooth" });
          }
          message += `Please pick game ${games[j].data().Title} \n`;
        }
      }
    }
    return message;
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div id="top-of-it-all">
      {!userView && (
        <Alert color="info">
          <b>
            Don't forget to click Save at the bottom! It will validate to make
            sure you have picked every game on every pick list!
          </b>
        </Alert>
      )}
      {disabled && !userView && (
        <Alert color="warning">Picking is currently disabled!</Alert>
      )}
      <Nav tabs>
        {picks.map((p, i) => (
          <NavItem key={"nav" + i}>
            <NavLink
              className={classnames({ active: activeTab === i })}
              onClick={() => {
                toggle(i);
              }}
            >
              {p.name}
              {i !== 0 ? (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteFrom(i);
                  }}
                  style={{ marginLeft: "2rem" }}
                  close
                  disabled={disabled}
                />
              ) : null}
            </NavLink>
          </NavItem>
        ))}
        <Button
          onClick={() =>
            setPicks([
              ...picks,
              {
                name: user.displayName + " " + picks.length || "New Picks",
                picks: [],
                points: 0,
                paid: false,
                userId: user.uid,
              },
            ])
          }
          disabled={disabled}
        >
          +
        </Button>
      </Nav>
      <Form>
        {validationMessage !== "" ? (
          <Alert
            color="danger"
            style={{ whiteSpace: "pre", marginTop: "1rem" }}
          >
            <>{validationMessage}</>
          </Alert>
        ) : null}
        {successMessage !== "" ? (
          <Alert color="success" style={{ marginTop: "1rem" }}>
            {successMessage}
          </Alert>
        ) : null}
        <FormGroup>
          <Label for="name">Name</Label>
          <Input
            onChange={(e) => setKeyVal("name", e.target.value, activeTab)}
            value={picks[activeTab] && picks[activeTab].name}
            type="text"
            name="name"
            id="name"
            placeholder="Name for picks"
            disabled={disabled}
          />
          <p>
            This is the name that will show on the leaderboard for your picks.{" "}
            <br />
            If you choose the right winner for a game you will receive the point
            value to the right of the logo
          </p>
        </FormGroup>
        {!loading &&
          value &&
          value.docs.map((doc, i) => {
            let game = doc.data();
            let time = new Date(game.DateTime);
            let awayTeam = {
              rank: game.AwayRank,
              teamName: game.AwayTeamName,
              team: game.AwayTeam,
              logo: game.AwayLogo,
              points: game.AwayPoints,
            };
            let homeTeam = {
              rank: game.HomeRank,
              teamName: game.HomeTeamName,
              team: game.HomeTeam,
              logo: game.HomeLogo,
              points: game.HomePoints,
            };
            if (game.Title === "National Championship") {
              const awayChamp = value.docs.find((doc) => {
                return (
                  doc.data().AwayTeam ===
                    picks[activeTab].picks[champAwayIndex] ||
                  doc.data().HomeTeam === picks[activeTab].picks[champAwayIndex]
                );
              });
              if (
                awayChamp &&
                awayChamp.data().AwayTeam ===
                  picks[activeTab].picks[champAwayIndex]
              ) {
                awayTeam = {
                  rank: awayChamp.data().AwayRank,
                  teamName: awayChamp.data().AwayTeamName,
                  team: awayChamp.data().AwayTeam,
                  logo: awayChamp.data().AwayLogo,
                  points: game.AwayPoints,
                };
              } else if (awayChamp) {
                awayTeam = {
                  rank: awayChamp.data().HomeRank,
                  teamName: awayChamp.data().HomeTeamName,
                  team: awayChamp.data().HomeTeam,
                  logo: awayChamp.data().HomeLogo,
                  points: game.AwayPoints,
                };
              }
              const homeChamp = value.docs.find((doc) => {
                return (
                  doc.data().AwayTeam ===
                    picks[activeTab].picks[champHomeIndex] ||
                  doc.data().HomeTeam === picks[activeTab].picks[champHomeIndex]
                );
              });
              if (
                homeChamp &&
                homeChamp.data().AwayTeam ===
                  picks[activeTab].picks[champHomeIndex]
              ) {
                homeTeam = {
                  rank: homeChamp.data().AwayRank,
                  teamName: homeChamp.data().AwayTeamName,
                  team: homeChamp.data().AwayTeam,
                  logo: homeChamp.data().AwayLogo,
                  points: game.HomePoints,
                };
              } else if (homeChamp) {
                homeTeam = {
                  rank: homeChamp.data().HomeRank,
                  teamName: homeChamp.data().HomeTeamName,
                  team: homeChamp.data().HomeTeam,
                  logo: homeChamp.data().HomeLogo,
                  points: game.HomePoints,
                };
              }
            }
            return (
              <Card
                key={game.Title}
                id={game.GameID}
                style={{ marginTop: "1rem" }}
              >
                <CardBody>
                  <CardTitle style={{ fontWeight: "bold" }}>
                    <h5>
                      {game.Title}
                      {game.Title === "National Championship" && (
                        <span>
                          <br />
                          (Your Rose Bowl and Sugar Bowl Winners)
                        </span>
                      )}
                      <br />
                    </h5>
                    {time.toLocaleDateString() +
                      " " +
                      time.toLocaleTimeString()}
                  </CardTitle>
                  <hr />
                  <CardText
                    style={{ fontFamily: "inherit", whiteSpace: "pre" }}
                  >
                    <b style={{ marginRight: "4px" }}>{awayTeam.rank}</b>
                    {`${awayTeam.teamName}  `}
                    {awayTeam.logo && (
                      <img
                        alt="away-logo"
                        src={awayTeam.logo}
                        style={{
                          height: "2rem",
                          width: "auto",
                          marginRight: "1rem",
                        }}
                      />
                    )}
                    <b>{awayTeam.points}</b>
                    <br />
                    at
                    <br />
                    <b style={{ marginRight: "4px" }}>{homeTeam.rank}</b>
                    {`${homeTeam.teamName}  `}
                    {homeTeam.logo && (
                      <img
                        style={{
                          height: "2rem",
                          width: "auto",
                          marginRight: "1rem",
                        }}
                        alt="home-logo"
                        src={homeTeam.logo}
                      />
                    )}
                    <b>{homeTeam.points}</b>
                  </CardText>
                  <ButtonGroup style={{ width: "100%" }}>
                    <Button
                      outline={!(picks[activeTab].picks[i] === awayTeam.team)}
                      active={picks[activeTab].picks[i] === awayTeam.team}
                      onClick={() => pickGame(i, awayTeam.team)}
                      color="info"
                      disabled={disabled}
                    >
                      {awayTeam.team}
                    </Button>
                    <Button
                      outline={!(picks[activeTab].picks[i] === homeTeam.team)}
                      active={picks[activeTab].picks[i] === homeTeam.team}
                      onClick={() => pickGame(i, homeTeam.team)}
                      color="info"
                      disabled={disabled}
                    >
                      {homeTeam.team}
                    </Button>
                  </ButtonGroup>
                </CardBody>
              </Card>
            );
          })}

        {!disabled ? (
          <div>
            {validationMessage !== "" ? (
              <Alert
                color="danger"
                style={{ whiteSpace: "pre", marginTop: "1rem" }}
              >
                <>{validationMessage}</>
              </Alert>
            ) : null}
            <Button
              color="primary"
              style={{ marginTop: "2rem" }}
              onClick={() => savePicks()}
              block
              disabled={disabled}
            >
              Save Picks
            </Button>
          </div>
        ) : null}
      </Form>
    </div>
  );
}
