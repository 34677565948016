import React from "react";
import app from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import Loading from "./Loading";
import { Link } from "react-router-dom";
import YearSelect from "./YearSelect";
import { ListGroup, ListGroupItem, Badge } from "reactstrap";
import { useObjectVal } from "react-firebase-hooks/database";

export default function Leaderboard() {
  const [year, setYear] = React.useState("2023");
  const [picks, initPicks, picksError] = useCollectionData(
    app
      .firestore()
      .collection(`picks${year}`)
      .where("paid", "==", true)
      .orderBy("points", "desc"),
  );
  const [games, initGames, gamesError] = useCollectionData(
    app
      .firestore()
      .collection(`games${year}`)
      .where("Enabled", "==", true)
      .orderBy("DateTime", "asc"),
  );
  const [config, loading, configError] = useObjectVal(
    app.database().ref("config"),
  );
  console.log("picks", picks);
  console.log("initPicks", initPicks);
  console.log("picksError", picksError);
  if (initPicks || picksError || loading || initGames) {
    return <Loading />;
  }
  console.log(picks, "picks");
  let pointPicks = picks.map((v, i) => {
    const winnerPicks = [...v.picks];
    if (v.picks[39] === "FIESTA") {
      winnerPicks[39] = winnerPicks[20];
    } else if (v.picks[39] === "PEACH") {
      winnerPicks[39] = winnerPicks[19];
    } else {
      console.log("error");
    }
    return {
      ...v,
      points: games.reduce(function (accumulator, currentValue, index) {
        if (currentValue.Winner === winnerPicks[index]) {
          if (currentValue.Winner === currentValue.HomeTeam) {
            return accumulator + currentValue.HomePoints;
          } else {
            return accumulator + currentValue.AwayPoints;
          }
        } else {
          return accumulator + 0;
        }
      }, 0),
    };
  });
  console.log(
    pointPicks.sort((a, b) => {
      if (a.points > b.points) return -1;
      if (b.points < a.points) return 1;
      return 0;
    }),
  );

  let cursor = 0;
  return (
    <div style={{ textAlign: "left" }}>
      <h3>Leaderboard</h3>
      <YearSelect value={year} prefix="" cb={setYear} />
      <ListGroup>
        {pointPicks
          .sort((a, b) => a.points > b.points)
          .map((v, i) => {
            if (!pointPicks[i - 1]) {
              cursor++;
            } else if (pointPicks[i - 1].points !== v.points) {
              cursor++;
            }
            return (
              <ListGroupItem
                tag={Link}
                disabled={config[`pickingAllowed${year}`]}
                to={`/user/${v.userId}`}
                key={`${v.name}s-picks-${v.userId}`}
                tag={Link}
                style={{
                  display: "flex",
                  color: "inherit",
                  textDecoration: "none",
                }}
                className="justify-content-between"
              >
                <span>{`${cursor}. \t${v.name}`}</span>
                <Badge
                  pill
                  color="primary"
                  style={{ fontSize: "15px", marginLeft: "auto" }}
                >
                  {v.points}
                </Badge>
              </ListGroupItem>
            );
          })}
      </ListGroup>
    </div>
  );
}
