import React from "react";
import Loading from "./Loading";
import { useAuthState } from "react-firebase-hooks/auth";
import app from "firebase/app";
import {
  useDocument,
  useCollection,
  useCollectionOnce,
  useCollectionDataOnce,
} from "react-firebase-hooks/firestore";
import { useObjectVal } from "react-firebase-hooks/database";
import YearSelect from "./YearSelect";
import Picks, { gamePicks } from "./Picks";
export default function PickWrapper() {
  const [year, setYear] = React.useState("2023");
  const [user, initialising, userError] = useAuthState(app.auth());
  const [config, loading, configError] = useObjectVal(
    app.database().ref("config"),
  );
  const [userData, initUser, dataError] = useCollectionOnce(
    app.firestore().doc(`users/${user.uid}`),
  );
  // const [picks, initPicks, picksError] = useCollection(
  //   app.firestore().collection('picks').where("id", "in", userData == null ? [] : userData.picks)
  // )
  if (initialising || initUser || loading) {
    return <Loading />;
  }
  if (
    userData &&
    userData.data() &&
    userData.data()[`picks${year}`] &&
    userData.data()[`picks${year}`].length > 0
  ) {
    console.log("pick IDS!!!", userData.data()[`picks${year}`]);
    return (
      <div>
        <YearSelect value={year} prefix="" cb={setYear} />
        <IdsWrapper
          year={year}
          user={user}
          pickIds={userData.data()[`picks${year}`]}
        />
      </div>
    );
  }
  return (
    <div>
      <YearSelect value={year} prefix="" cb={setYear} />
      <Picks
        user={user}
        year={year}
        disabled={!config[`pickingAllowed${year}`]}
        userPicks={[
          {
            name: user.displayName || "New Picks",
            picks: [],
            points: 0,
            paid: false,
            userId: user.uid,
          },
        ]}
      />
    </div>
  );
}

export function IdsWrapper({ pickIds, user, year }) {
  const [picks, initPicks, picksError] = useCollectionDataOnce(
    app
      .firestore()
      .collection(`picks${year}`)
      .where(app.firestore.FieldPath.documentId(), "in", pickIds),
  );

  const [config, loading, configError] = useObjectVal(
    app.database().ref("config"),
  );
  console.log(picks, "picks!", picksError);
  if (initPicks || loading) {
    return <Loading />;
  }
  return (
    <div>
      <Picks
        disabled={!config[`pickingAllowed${year}`]}
        year={year}
        user={user}
        userPicks={picks.sort((a, b) => (a.name > b.name ? 1 : -1))}
        pickIds={pickIds}
      />
    </div>
  );
}
