import React, { useState } from "react";
import app from "firebase/app";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  Card,
  CardTitle,
  CardBody,
  CardText,
  Col,
  Form,
  FormGroup,
  Row,
  Label,
  Input,
  ButtonGroup,
  Button,
} from "reactstrap";
import { useObject } from "react-firebase-hooks/firestore";
import Toggle from "react-toggle";
import Loading from "./Loading";
export default function Games({ year }) {
  const [games, loading, error] = useCollection(
    app.firestore().collection(`games${year}`).orderBy("DateTime", "asc")
  );
  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Row style={{ margin: ".5rem" }}>
        {games.docs.map((doc, i) => {
          let game = doc.data();
          const time = new Date(game.DateTime);
          return (
            <Col sm="12" key={game.Title} style={{ marginBottom: "1rem" }}>
              <Card key={game.Title} body>
                <CardTitle style={{ fontWeight: "bold" }}>
                  <h5>{game.Title}</h5>
                  <br />
                  {time.toLocaleDateString() + " " + time.toLocaleTimeString()}
                </CardTitle>
                <Form>
                  <Label>Enabled?</Label> <br />
                  <Toggle
                    checked={game.Enabled}
                    onChange={() =>
                      app
                        .firestore()
                        .collection(`games${year}`)
                        .doc(doc.id)
                        .set(
                          { Enabled: !game.Enabled },
                          {
                            merge: true,
                          }
                        )
                    }
                  />
                </Form>
                <Form>
                  <FormGroup>
                    <Label>
                      <b style={{ marginRight: "4px" }}>{game.AwayRank}</b>
                      {game.AwayTeamName}
                      <img
                        alt="away-logo"
                        src={game.AwayLogo}
                        style={{
                          marginLeft: "4px",
                          height: "2rem",
                          width: "auto",
                        }}
                      />
                    </Label>
                    <Input
                      style={{ width: "auto", margin: "auto" }}
                      value={game.AwayPoints}
                      onChange={(e) =>
                        app
                          .firestore()
                          .collection(`games${year}`)
                          .doc(doc.id)
                          .set(
                            {
                              AwayPoints: parseInt(e.target.value),
                            },
                            {
                              merge: true,
                            }
                          )
                      }
                      type="number"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <b style={{ marginRight: "4px" }}>{game.HomeRank}</b>
                      {game.HomeTeamName}
                      <img
                        alt="home-logo"
                        src={game.HomeLogo}
                        style={{
                          height: "2rem",
                          marginLeft: "4px",
                          width: "auto",
                        }}
                      />
                    </Label>
                    <Input
                      value={game.HomePoints}
                      onChange={(e) =>
                        app
                          .firestore()
                          .collection(`games${year}`)
                          .doc(doc.id)
                          .set(
                            {
                              HomePoints: parseInt(e.target.value),
                            },
                            {
                              merge: true,
                            }
                          )
                      }
                      style={{ width: "auto", margin: "auto" }}
                      type="number"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Winner</Label>
                    <br />
                    <ButtonGroup style={{ width: "100%" }}>
                      <Button
                        outline={
                          i === 100
                            ? game.Winner !== "LSU"
                            : game.Winner !== game.AwayTeam
                        }
                        active={
                          i === 100
                            ? game.Winner === "LSU"
                            : game.Winner === game.AwayTeam
                        }
                        color="primary"
                        onClick={() =>
                          app
                            .firestore()
                            .collection(`games${year}`)
                            .doc(doc.id)
                            .set(
                              {
                                Winner: i == 100 ? "LSU" : game.AwayTeam,
                              },
                              {
                                merge: true,
                              }
                            )
                        }
                      >
                        {i === 100 ? "LSU" : game.AwayTeam}
                      </Button>
                      <Button
                        outline={
                          i === 100
                            ? game.Winner !== "CLMSN"
                            : game.Winner !== game.HomeTeam
                        }
                        active={
                          i === 100
                            ? game.Winner === "CLMSN"
                            : game.Winner === game.HomeTeam
                        }
                        color="primary"
                        onClick={() =>
                          app
                            .firestore()
                            .collection(`games${year}`)
                            .doc(doc.id)
                            .set(
                              {
                                Winner: i === 100 ? "CLMSN" : game.HomeTeam,
                              },
                              {
                                merge: true,
                              }
                            )
                        }
                      >
                        {i === 100 ? "CLMSN" : game.HomeTeam}
                      </Button>
                    </ButtonGroup>
                    <Button
                      outline={true}
                      style={{ marginTop: "1rem" }}
                      onClick={() =>
                        app
                          .firestore()
                          .collection(`games${year}`)
                          .doc(doc.id)
                          .set(
                            {
                              Winner: "",
                            },
                            {
                              merge: true,
                            }
                          )
                      }
                    >
                      Clear
                    </Button>
                  </FormGroup>
                </Form>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
