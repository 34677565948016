import app from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import React from "react"

const config = {
  apiKey: "AIzaSyDiGbPxZRI4tXXFfcZZQZStC4cp8su1aAc",
  authDomain: "griesers-bowl-picks.firebaseapp.com",
  databaseURL: "https://griesers-bowl-picks.firebaseio.com",
  projectId: "griesers-bowl-picks",
  storageBucket: "griesers-bowl-picks.appspot.com",
  messagingSenderId: "42081056733",
  appId: "1:42081056733:web:47f471172abd5cc1dda834",
  measurementId: "G-EK0WG6TEDL"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
     this.auth = app.auth;
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);
}

export default Firebase;

export const FirebaseContext = React.createContext(null);