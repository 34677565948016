import React from "react";
import { Col, Label, Input, FormGroup } from "reactstrap";

export default function YearSelect(props) {
  const prefix = props.prefix;
  const cb = props.cb;
  return (
    <FormGroup row>
      <Label for="year" sm={2}>
        Change Year
      </Label>
      <Col sm={10}>
        <Input
          type="select"
          name="year"
          id="year"
          value={props.value}
          onChange={(e) => cb(e.target.value)}
        >
          <option value={prefix}>2019</option>
          <option value={prefix + "2020"}>2020</option>
          <option value={prefix + "2021"}>2021</option>
          <option value={prefix + "2022"}>2022</option>
          <option value={prefix + "2023"}>2023</option>
        </Input>
      </Col>
    </FormGroup>
  );
}
