import React from "react";
import {
  useList,
  useObject,
  useObjectVal,
} from "react-firebase-hooks/database";
import { Form, FormGroup, Input, Label } from "reactstrap";
import app from "firebase/app";
import Loading from "./Loading";
import Toggle from "react-toggle";
import "react-toggle/style.css";

export default function Config() {
  const [config, loading, configError] = useObjectVal(
    app.database().ref("config"),
  );
  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Form>
        <FormGroup>
          <Label>Jumbotron Message</Label>
          <Input
            type="textarea"
            style={{ height: "10rem" }}
            onChange={(e) =>
              app.database().ref("config/jumboMessage").set(e.target.value)
            }
            value={config.jumboMessage}
            name="jumbo"
            id="jumbo"
          />
        </FormGroup>
        <FormGroup>
          <Label>Below Jumbo Message</Label>
          <Input
            type="textarea"
            style={{ height: "10rem" }}
            onChange={(e) =>
              app.database().ref("config/belowMessage").set(e.target.value)
            }
            value={config.belowMessage}
            name="below"
            id="below"
          />
        </FormGroup>
        <FormGroup>
          <Label>Picking Allowed? 2019</Label> <br />
          <Toggle
            checked={config.pickingAllowed}
            onChange={() =>
              app
                .database()
                .ref("config/pickingAllowed")
                .set(!config.pickingAllowed)
            }
          />
        </FormGroup>
        <FormGroup>
          <Label>Picking Allowed? 2020</Label> <br />
          <Toggle
            checked={config.pickingAllowed2020}
            onChange={() =>
              app
                .database()
                .ref("config/pickingAllowed2020")
                .set(!config.pickingAllowed2020)
            }
          />
        </FormGroup>
        <FormGroup>
          <Label>Picking Allowed? 2021</Label> <br />
          <Toggle
            checked={config.pickingAllowed2021}
            onChange={() =>
              app
                .database()
                .ref("config/pickingAllowed2021")
                .set(!config.pickingAllowed2021)
            }
          />
        </FormGroup>
        <FormGroup>
          <Label>Picking Allowed? 2022</Label> <br />
          <Toggle
            checked={config.pickingAllowed2022}
            onChange={() =>
              app
                .database()
                .ref("config/pickingAllowed2022")
                .set(!config.pickingAllowed2022)
            }
          />
        </FormGroup>
        <FormGroup>
          <Label>Picking Allowed? 2023</Label> <br />
          <Toggle
            checked={config.pickingAllowed2023}
            onChange={() =>
              app
                .database()
                .ref("config/pickingAllowed2023")
                .set(!config.pickingAllowed2023)
            }
          />
        </FormGroup>
      </Form>
    </div>
  );
}
