import React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import app from "firebase/app";
import PickLists from "./PickLists";
import Loading from "./Loading";
import { Card, CardTitle, CardText, Col, Row } from "reactstrap";
export default function Paid({ year }) {
  const [users, loading, errorUsers] = useCollectionData(
    app.firestore().collection("users")
  );
  console.log(users)
  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Row>
        {users.filter(user => user[`picks${year}`] && user[`picks${year}`].length > 0).map((user, i) => (
          <Col sm="4" style={{ margin: '.5rem' }} key={user.displayName + i}>
            <Card body>
              <CardTitle
                style={{ margin: "1rem", borderBottom: "2px solid white" }}
              >
                <h2 style={{ margin: 0 }}>{user.displayName}</h2>
              </CardTitle>
              {
                user[`picks${year}`] && user[`picks${year}`].length > 0 ? <PickLists ids={user[`picks${year}`] || []} yearKey={year} /> : <div />
              }
            </Card>
          </Col>
        ))}
      </Row>
    </div>

  );
}
