import React from "react";
import { Jumbotron, Container } from "reactstrap";
import {
  useObjectVal
} from "react-firebase-hooks/database";
import app from "firebase/app";
import Loading from "./Loading";
import Leaderboard from "./Leaderboard";
export default function Home() {
  const [config, loading, configError] = useObjectVal(
    app.database().ref("config")
  );
  if (loading) {
    return <Loading />;
  }
  console.log(config.jumboMessage)
  return (
    <div>
      <Jumbotron fluid>
        <Container fluid>
          <div
            dangerouslySetInnerHTML={{
              __html: config.jumboMessage
            }}
          />
        </Container>
      </Jumbotron>
      <div 
        dangerouslySetInnerHTML={{
          __html: config.belowMessage
        }}
      />

      <Leaderboard />
    </div>
  );
}
